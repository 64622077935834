exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-be-better-js": () => import("./../../../src/pages/be_better.js" /* webpackChunkName: "component---src-pages-be-better-js" */),
  "component---src-pages-beats-js": () => import("./../../../src/pages/beats.js" /* webpackChunkName: "component---src-pages-beats-js" */),
  "component---src-pages-bloom-js": () => import("./../../../src/pages/bloom.js" /* webpackChunkName: "component---src-pages-bloom-js" */),
  "component---src-pages-celso-js": () => import("./../../../src/pages/celso.js" /* webpackChunkName: "component---src-pages-celso-js" */),
  "component---src-pages-classics-js": () => import("./../../../src/pages/classics.js" /* webpackChunkName: "component---src-pages-classics-js" */),
  "component---src-pages-diy-js": () => import("./../../../src/pages/diy.js" /* webpackChunkName: "component---src-pages-diy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lolrolls-js": () => import("./../../../src/pages/lolrolls.js" /* webpackChunkName: "component---src-pages-lolrolls-js" */),
  "component---src-pages-loveandbeyond-js": () => import("./../../../src/pages/loveandbeyond.js" /* webpackChunkName: "component---src-pages-loveandbeyond-js" */),
  "component---src-pages-now-js": () => import("./../../../src/pages/now.js" /* webpackChunkName: "component---src-pages-now-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-sanity-js": () => import("./../../../src/pages/sanity.js" /* webpackChunkName: "component---src-pages-sanity-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

